<template>
  <v-dialog v-model="value" persistent scrollable max-width="90vw">
    <v-card v-if="loading">
      <v-card-text class="mt-4 text-center">
        <h2 class="mt-4 mb-4 text-center">
          Please wait while we update related products
        </h2>
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="green"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
    <v-card v-if="artist && !loading">
      <v-card-title class="pa-0">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{
            newArtist ? "New Artist" : artist.name
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="artistForm" v-model="formValid" :lazy-validation="true">
          <v-text-field
            v-model="artist.name"
            label="Name"
            :rules="[rules.required]"
            @blur="isDirty = true"
          ></v-text-field>
          <v-text-field
            v-model="artist.artistCode"
            label="Artist Code"
            :rules="
              newArtist
                ? [rules.uniqueArtistCode, rules.artistCode, rules.required]
                : []
            "
            @change="isDirty = true"
            :disabled="!newArtist"
          ></v-text-field>
          <v-text-field
            v-if="!newArtist && !products?.length"
            v-model="artist.status"
            label="Status. To change Artist status, create some products"
            :disabled="true"
          ></v-text-field>
          <v-select
            v-if="products?.length"
            :items="['PENDING', 'ACTIVE', 'ARCHIVED']"
            label="Status"
            @change="isDirty = true"
            v-model="artist.status"
          ></v-select>
          <v-select
            v-if="!newArtist"
            :items="artworks"
            label="Signature Image"
            v-model="artist.signatureImage"
            item-value="file"
          >
            <template v-slot:selection="{ item, index }">
              <div class="d-flex align-center my-2 py-2">
                <v-img
                  max-height="50px"
                  max-width="50px"
                  :src="item.imgUrl"
                /><span class="mx-2">{{ item.title }} {{ item.file }}</span>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex align-center py-2">
                <v-img
                  max-height="50px"
                  max-width="50px"
                  :src="item.imgUrl"
                /><span class="mx-2">{{ item.title }} {{ item.file }}</span>
              </div>
            </template>
          </v-select>
          <label>Bio</label>
          <vue-editor
            ref="bio"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            @text-change="handleTextChange"
            v-model="artist.bio"
          ></vue-editor>
          <div v-html="artist.bio"></div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          :disabled="!formValid || !isDirty"
          class="mr-4"
          @click.native="saveArtist()"
        >
          Save
        </v-btn>
        <v-btn
          color="success"
          :disabled="!formValid || !isDirty"
          class="mr-4"
          @click.native="close()"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vFormBase from "@/components/vFormBase";
import ArtistApi from "@/api/admin/artist";
import ArtworkApi from "@/api/artwork";
import {
  resizeImage,
  readFileAsDataURL,
  dataURLtoFile,
} from "@/utilities/imageUtilities";
import ImageResize from "quill-image-resize-module";
import { VueEditor } from "vue2-editor";

export default {
  props: {
    value: Boolean,
    data: Object,
    artistCodes: Array,
  },
  components: {
    VueEditor,
  },
  async mounted() {
  },
  data() {
    return {
      artist: this.data || {},
      artworks: [],
      products: [],
      loading: false,
      isDirty: false,
      newArtist: true,
      formValid: false,
      content: null,
      customModulesForEditor: [{ alias: "imageResize", module: ImageResize }],
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      rules: {
        required: (value) => !!value || "Required.",
        uniqueArtistCode: (value) => {
          const check = !this.artistCodes.includes(value?.toUpperCase());
          return (
            check || `Artist Code ${value?.toUpperCase()} is already in use`
          );
        },
        artistCode: (v) =>
          /^[a-zA-Z0-9]*$/.test(v) || "Only letters and numbers allowed",
      },
    };
  },
  computed: {},
  async created() {
    if (this.data) {
      this.artistRules = [
        this.rules.uniqueArtistCode,
        this.rules.artistCode,
        this.rules.required,
      ];
      this.products = this.data.products

      this.artworks = this.data.artworks.map((artwork) => {
        return {
          imgUrl: `${process.env.VUE_APP_IMG_URL}/100px/${artwork.image}`,
          title: artwork.title,
          file: artwork.image,
        };
      });
      this.newArtist = false;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleTextChange(data) {
      if (data.ops.length === 1 ) return
      this.isDirty = true
      return
    },
    async saveArtist(close) {
      this.loading = true;
      const artistImage = `${this.artist.artistCode.toUpperCase()}.jpg`;
      // if ( this.artist.artistImage) {
      //   const b64str = await readFileAsDataURL(this.artist.artistImage);
      //   const _IMG1500 = await resizeImage(b64str, { maxHeight: 1500, maxWidth: 1500 } );
      //   const _IMG300 = await resizeImage(b64str, { maxHeight: 300, maxWidth: 300 } );
      //   const _IMG100 = await resizeImage(b64str, { maxHeight: 100, maxWidth: 100 } );
      //   const px300=document.getElementById('px300');
      //   px300.src=(_IMG300.url);
      //   ArtworkApi.uploadArtworkImage( { path: '1500px', name: artistImage, file: dataURLtoFile(_IMG1500.url) })
      //   ArtworkApi.uploadArtworkImage( { path: '300px', name: artistImage, file: dataURLtoFile(_IMG300.url) })
      //   ArtworkApi.uploadArtworkImage( { path: '100px', name: artistImage, file: dataURLtoFile(_IMG100.url) })
      // }
      const query = this.data ? "updateArtist" : "createArtist";
      ArtistApi[query]({
        name: this.artist.name,
        artistCode: this.artist.artistCode.toUpperCase(),
        signatureImage: this.artist.signatureImage,
        image: artistImage,
        bio: this.artist.bio,
        status: this.artist.status,
      })
        .then((results) => {
          this.artist = { ...results.data };
          this.loading = false;
          this.isDirty = false;
          this.close();
        })
        .catch((err) => {
          console.log("err", err);
          this.loading = false;
        });
    },
    async handleEvent(evt) {
      this.isDirty = true;
      switch (evt.key) {
        case "artistImage": {
          const artistImage = `${this.artist.artistCode.toUpperCase()}.jpg`;
          if (this.artist.artistImage) {
            const b64str = await readFileAsDataURL(this.artist.artistImage);
            const _IMG300 = await resizeImage(b64str, {
              maxHeight: 300,
              maxWidth: 300,
            });
            const px300 = document.getElementById("px300");
            px300.src = _IMG300.url;
          } else px300.removeAttribute("src");
          break;
        }
        default:
          console.log(evt);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~vue2-editor/dist/vue2-editor.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px;
}
</style>

import { render, staticRenderFns } from "./ArtistDialog.vue?vue&type=template&id=291b3ec2&scoped=true"
import script from "./ArtistDialog.vue?vue&type=script&lang=js"
export * from "./ArtistDialog.vue?vue&type=script&lang=js"
import style0 from "./ArtistDialog.vue?vue&type=style&index=0&id=291b3ec2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291b3ec2",
  null
  
)

export default component.exports